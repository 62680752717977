import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Footer.css";
import { Link } from "react-router-dom";
import CareGemLogo from "../assets/Caregem.svg";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <Container fluid>
          <Row fluid className="footer-top">
            <Col xs={12} sm={12} md={1} lg={1} />
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="footer-col footer-col-left"
            >
              <img
                src={CareGemLogo}
                className="footer-caregem-logo"
                width="40"
                alt="caregem-logo"
              />
              <ul>
                <li>© 2022-23 CareGem Health Inc. All Rights Reserved.</li>
                <li style={{ textDecoration: "underline" }}>
                  <Link to="/legal">Privacy Policy Terms & Conditions</Link>
                </li>
              </ul>
              <ul>
                <li>10326 Sandy Lane, Munster, IN</li>
                <li>46321, USA</li>
              </ul>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={3}
              lg={3}
              className="footer-col footer-col-center"
            >
              <ul>
                <li>
                  <strong>Caregem</strong>
                </li>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="#partners">Partners</a>
                </li>
                <li>
                  <a href="#patients">Patients</a>
                </li>
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={3}
              lg={3}
              className="footer-col footer-col-right"
            >
              <ul>
                <li>
                  <strong>Resources</strong>
                </li>
                <li>
                  <a href="https://ns-nwi.com/">Nephrology Specialists</a>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={12} md={1} lg={1} className="footer-col" />
          </Row>
        </Container>
      </div>
    );
  }
}
